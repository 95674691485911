#menubutton {
    position: fixed;
    z-index: 200;
    @include rem-calc-sle(right, 60, 24);
    @include rem-calc-sle(top, 70, 36);
    right: rem-calc(60);
    top: rem-calc(70);

    cursor: pointer;

    color:$blau;

    mix-blend-mode: multiply;
}

//animated hamburger icon
#menubutton {
    display: flex;
    @include rem-calc-sl(gap, 16, 0.75);
    align-items: center;

    transition:color .2s;
    body.overlaymenuvisible & {
        color:$white;
        mix-blend-mode: normal;
    }

    .hbicon {
        @include rem-calc-sl(width, 28, 1);
        @include rem-calc-sl(height, 22, 1);

        position: relative;

        .line1,
        .line2,
        .line3 {
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: currentColor;

            transition: width .4s, opacity .2s;
            body.overlaymenuvisible & {
                opacity: 0;
            }
        }
        .line1 {
            top: 0;
            width: 78%;
        }
        .line2 {
            top: calc(50% - 1.5px);
            width: 57%;
        }
        .line3 {
            bottom: 0;
        }

        .icon-menuclosebutton {
            position: absolute;
            left:0;
            top:0;
            height:100%;

            opacity: 0;
            transition: opacity .2s, transform .2s;

            &:hover {
                transform: scale(1.2);
            }

            body.overlaymenuvisible & {
                opacity: 1;
            }
        }
    }

    &:hover .hbicon {
        .line1,
        .line2,
        .line3 {
            width: 100%;
        }
    }
}

#menubutton {
    @include breakpoint(small down) {
        display: none;
    }
}

//typo
#menubutton {
    font-family:'BreuerTextGS', sans-serif;
    @include font-size-pt(25, 25);
    font-weight: 400;
    line-height: math.div(30, 25);

    .menulabel {
        margin-bottom: -0.15em; //Fehlende Unterlaenge kompensieren
    }
}