//.buttonbar {}

.button {
  $paddingY: 0.6em;
  $paddingX: 1.5em;
  display: block;
  width: fit-content;
  padding: $paddingY $paddingX;
  color: white;
  border-radius: 2px;
  background-color: $orange;
  
  cursor: pointer;


  transition: padding .4s, margin .4s;

  &:hover {
    $additionalHoverPadding:0.25em;
    padding:$paddingY+$additionalHoverPadding $paddingX+$additionalHoverPadding;
    margin:-0.25em -0.25em;

    box-shadow: 0px 2px 4px 0px #00000040;
  }
}
button.button {
  //remove default <button> styling
  border:none;
  outline: none;
}

//button and arrow alignment
.button {
  display:flex;
  align-items: center;
  gap:0.5em;

  .icon-buttonarrowright {
    margin-top: -1px;
  }
}

//typo
.button {
  @include typo-button;
}