@mixin bulletlist {
  //V1: using pseudo element ::before
  $listIndent:0.9em;
  margin-left: $listIndent+0.1em;
  padding-left: 0;
  list-style-type: none;
  li {
    &::before {
      content: "";
      margin-left: -$listIndent;
      margin-right: 0;
      width: $listIndent;
      height:1em;
      display:inline-block;

      background:linear-gradient(to bottom, $black, $black);
      background-position:left 75%;
      background-repeat: no-repeat;
      background-size: 8px 8px;
    }
  }
  
  //level 2
  ul {
    li::before {
      background-size: 5px 5px;
      background-position:left 72%;
    }
  }

  /*
  //V2, using builtin symbols
  margin-left: 0.9em;
  padding-left: 0;
  list-style-type: square;

  li::marker {
    color:inherit;
  }

  //level 2
  ul {
    li::marker {
      font-size: 0.7em;
      transform: translateY(-70%);
      display: block;
    }
  }
  */
}

.bl-richtext ul,
.bl-textwithimage .coltext ul {
  @include bulletlist;
  margin-top:0;
}


