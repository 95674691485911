//positioning
.bl-kontaktpersonen {
    @include breakpoint(large) {
        .ctrintro {
            @include grid12;
            .ctrtext {
                grid-column: 1 / span 7;
            }
        }

        .ctrpersonen {
            @include grid12;
            .item {
                grid-column: 1 / span 8;
            }
            .item:nth-child(2n) {
                grid-column: 5 / span 8;
            }
        }

        .ctrpersonen {
            margin-top:2.5em;
            .item {
                margin-top:2.5em;
            }
        }

        .itembox {
            @include grid;
            gap:0;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: 1fr auto;
            .ctrimage {
                grid-column:1 / span 3;
                grid-row:1 / span 2;
            }
            .ctrimagemobile {
                display:none;
            }
            .ctrinfo {
                grid-column:4 / span 5;
                grid-row:1;
            }
            .ctrmorelink {
                grid-column:4 / span 5;
                grid-row:2;
            }
        }

        .ctrshowallbutton {
            margin-top:1.75em;
        }
    }
    @include breakpoint(medium down) {
        .ctrpersonen .item {
            margin-top: 3em;
        }
        .ctrshowallbutton {
            margin-top:1.75em;
        }
    }
}

//styling
.bl-kontaktpersonen {
    @include defaultsectionpadding;

    .ctrimage {
        img {
            @include w100bl;
            @include breakpoint(large) {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .ctrinfo {
        @include verlauf-blau-hell;
        padding:0.75em;
        padding-bottom:1.5em;
    }
    .ctrmorelink {
        padding:0.5em 1em;
        span {
            display:flex;
            justify-content: flex-end;
            align-items: center;
            gap:0.5em;
        }
        @include verlauf-blau-dunkel;
    } 
    .socialicons {
        margin-top:1em;
        display:flex;
        gap:0.5em;
        .icon {
            display: block;
            height:rem-calc(30);
        }
    }
}

//hover effect
.bl-kontaktpersonen {
    .ctrinfo a {
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.6;
        }
    }
}

//hover effect more link
.bl-kontaktpersonen {
    .ctrmorelink {
        position: relative;
        span {
            position: relative; //bring to front
        }
        .bgoverlay {
            @include absposfull;
            opacity: 0;
            transition: opacity .4s;
            background-color:$blauMoreHover;
        }
        .icon-moreinfoarrow {
            transition:transform .4s;
        }
    }
    .ctrmorelink:hover {
        .bgoverlay {
            opacity: 1;
        }
        .icon-moreinfoarrow {
            transform: translateX(5px);
        }
    }
}

//typo
.bl-kontaktpersonen {
    .ctrinfo {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 25);
        font-weight: 400;
        line-height: math.div(32, 25);
        color:$white;
    }
    .ctrmorelink {
        @include typo-button;
        color:$white;
    }
}