footer {
  background-color: $bgblue;
  background-size: cover;
  @media(min-width:1600px) {
    background-size: 1600px auto;
  }
  background-position: right 20%;
  background-repeat: no-repeat;
  background-image: url(/assets/app/images/bg/footer.png);
  @include breakpoint(small down) {
    background-size: cover;
    background-position: right top;
    background-image: url(/assets/app/images/bg/footermobile.png);
  }

  @include rem-calc-sl(padding-top, 60, 0.6);
  @include rem-calc-sl(padding-bottom, 75, 0.6);
  @include breakpoint(small down) {
    padding-bottom:rem-calc(110);
  }

  @include breakpoint(large) {
    .ctrmain {
      @include grid;
      grid-template-areas:
        "claim claim" 
        "menuitems address";
    }
    .ctrclaim {
      grid-area: claim;
      margin-bottom:rem-calc(64);
    }
    .ctrmenuitems {
      grid-area: menuitems;
      justify-self:start;
      align-self:end;

      display:flex;
      align-items: flex-end;
      gap:4em;
    }
    .ctraddress {
      grid-area: address;
      justify-self:end;
      align-self:end;
    }
  }
  @include breakpoint(medium down) {
    .ctrmenuitems, .ctraddress {
      margin-top: rem-calc(80);
    }
    .ctrmenuitems {
      display:flex;
      align-items: flex-end;
      flex-wrap:wrap;
      gap:2em;
    }
  }
  @include breakpoint(medium only) {
    .ctrmenuitems {
      display:flex;
      align-items: flex-end;
      gap:4em;
    }
  }

  .ctrmenuitems {
    a {
      display: block;
    }
    a + a {
      margin-top:0.75em;
    }
  }

  .icon-linkedin {
    display: block;
    @include rem-calc-sle(height, 30, 30);
  }
}

//Footer in background
footer {
  position: fixed;
  bottom:0;
  left:0;
  width:100%;

  z-index:-1;
}
.ws36 {
  footer {
    position: static;
  }
  main {
    margin-bottom:0;
  }
}

//typo
footer {
  color:$white;

  .ctrclaim {
    font-family:'ElenaGS', serif;
    @include font-size-pt(24, 34);
    font-weight: 400;
    line-height: math.div(40, 34);
  }

  .ctrmenuitems, .ctraddress {
    @include font-size-pt(20, 20);
    font-weight: 400;
    line-height: math.div(24, 20);
  }
  .ctrmenuitems {
    font-family:'BreuerTextGS', sans-serif;
    letter-spacing: 0.02em;
  }
  .col {
    .active {
      @include text-decoration-underline;
      text-decoration-thickness: 1px;
    }
    a {
      transition: opacity 0.2s;
    }
    a:not(.active):hover {
      opacity:0.6;
    }
  }
  .ctraddress {
    strong {
      font-family:'BreuerTextGS', sans-serif;
      font-weight:700;
    }
  }

}

