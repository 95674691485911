body {
  @include rem-calc-sl(--headerheight, 170, math.div(92, 170));
}

header {
  position: absolute;
  left:0;
  right:0;
  top:0;
  z-index:10;

  //$paddingtop:60;
  //$paddingbottom:$paddingtop;

  //@include rem-calc-sl(padding-top, $paddingtop, 0.5);
  //@include rem-calc-sl(padding-bottom, $paddingbottom, 0.5);

  .ctrlr {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .ctrlogofs {
    position:absolute;
    @include rem-calc-sl(left, 60, math.div(20, 60));
    @include rem-calc-sl(top, 60, math.div(20, 60));
    @include breakpoint(medium down) {
      left:var(--lrminmargin);
    }

    img {
      display:block;
      @include rem-calc-sl(width, 66, math.div(54, 66));
    }
  }

  /*
  //sticky
  position:sticky;
  top:0;
  z-index: 10;
  background-color: $white;

  @include breakpoint(large) {
    //scroll down effect (make header smaller)
    transition: padding-top .2s, padding-bottom .2s;
    .ctrlogofs img {
      transition: width .2s;
    }
    &.small {
      @include rem-calc-sl(padding-top, $paddingtop*0.5, 0.5);
      @include rem-calc-sl(padding-bottom, $paddingbottom*0.5, 0.5);
        .ctrlogofs img {
        @include rem-calc-sl(width, $logowidth*0.6, 0.6);
      }
    }
  }
  */
}
