//positioning
.bl-filelist {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            .ctritems {
                grid-column: 1 / span 7;
            }
        }
    }
    @include breakpoint(medium down) {
    }
}

//styling
.bl-filelist {
    .item {
        padding-top:0.6em;
        padding-bottom:0.4em;

        border-top:1px solid $black;
        &:last-child {
            border-bottom:1px solid $black;
        }

        .date {
            margin-bottom: 0.5em;
        }

        $iconWidth:1.5em;
        .title {
            display:flex;
            .ctricon {
                flex-shrink: 0;
                width:$iconWidth;
            }
            .icon {
                display: block;
            }
        }
        .text {
            margin-left:$iconWidth;
        }
    }
}

//typo
.bl-filelist {
    .date {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 20);
        line-height:math.div(24, 20);
    }
    .title {
        color:$orange;
        transition:color 0.2s;
        &:hover {
            color:$orangeHover;
        }
    }
}