@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(20, 26);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(36, 26);
}

body {
  font-family:'ElenaGS', serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
  color:$textcolor;
}
body {
  font-variant-ligatures: contextual; //Ligatur fuer z.B. "Strateg*innen"
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(32, 48);
  font-weight: 700;
  line-height: math.div(56, 48);
  color:$blaudunkel;
}
@mixin typo-h2 {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(32, 48);
  font-weight: 700;
  line-height: math.div(56, 48);
  color:$blaudunkel;
}
@mixin typo-h3 {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(24, 34);
  font-weight: 700;
  line-height: math.div(40, 34);
  color:$blaudunkel;
}
@mixin typo-h4 {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(20, 26);
  font-weight: 700;
  line-height:inherit;
  color:inherit;
}

@mixin typo-lead {
  @include font-size-pt(28*0.75, 28);
  font-weight: 400;
  line-height:math.div(37, 28);
}
@mixin typo-quote {
  @include font-size-pt(32, 48);
  line-height: math.div(56, 48);
  color:$white;
}
@mixin typo-quoteauthor {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(19, 25);
  line-height: math.div(32, 25);
  color:$white;
}
@mixin typo-footer {
  @include font-size-pt(18*0.75, 18);
  font-weight: 400;
  line-height:math.div(22, 18);
}
@mixin typo-hero {
  @include font-size-pt(32, 48);
  font-weight: 400;
  line-height:math.div(56, 48);
}
@mixin typo-button {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(19, 20);
  font-weight: 400;
  line-height:math.div(24, 20);
  letter-spacing: 0.02em;
}
@mixin typo-menuitems {
  font-family:'BreuerTextGS', sans-serif;
  @include font-size-pt(28, 48);
  font-weight: 400;
  line-height:math.div(52, 48);
}

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}

h1, h3 {
  margin:0 0 0.75em 0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
  margin:0 0 1.5em 0;
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
  margin: 0;
}

@mixin contenttable {
  table {
    border-collapse:collapse;
  }
  th, td {
    border:1px solid $lightbg;
    padding:0.2em 0.5em;
  }
}

@mixin text-decoration-underline {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.2em;
  //text-decoration-skip-ink: none;
}

::selection {
  background: $orange;
  color: $white;
}