#overlaymenu {
    background-color: $bgblue;
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width:1600px) {
        background-size: 1600px auto;
    }
    background-image: url('/assets/app/images/bg/menu.png');
    @include breakpoint(small down) {
        background-image: url('/assets/app/images/bg/menumobile.png');
    }

    color:$white;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    
    overflow-y:auto;
    scrollbar-width: none;

    z-index: 100;

    overscroll-behavior: contain;

    /*
    .ctrclosebtn {
        padding-top:rem-calc(25);
        padding-bottom:rem-calc(25);
        .icon {
            cursor: pointer;
            display: block;
            margin-left:auto; //align right
            height:rem-calc(27);
        }
    }
    */

    @include breakpoint(large) {
        .ctrmenu {
            @include rem-calc-sl(padding-top, 200, 0.5);
            @include rem-calc-sl(padding-bottom, 170, 0.5);
            @media (max-height: 750px) {
                @include rem-calc-sl(padding-top, 50, 0.5);
                @include rem-calc-sl(padding-bottom, 50, 0.5);
            }

            width:rem-calc(400);
            margin-left: auto;
            min-height:100vh;
            box-sizing: border-box;

            display:flex;
            flex-direction: column;
            justify-content: space-between;
            gap:3em; //minimum space between menu and langchooser
        }
        .ctrlangchooser {
            //position: absolute;
            //top:calc(100dvh - #{rem-calc(170)});
        }
    }
    @include breakpoint(medium down) {
        .ctrmenu {
            display:flex;
            flex-direction: column;
            .ctrlangchooser {
                order:1;
            }
            .accordionmenu {
                order:2;
            }

            .ctrlangchooser {
                margin-top:rem-calc(160);
                @include breakpoint(small down) {
                    margin-top:rem-calc(80);
                }
            }
            .accordionmenu {
                margin-top:rem-calc(100);
            }

            margin-left:rem-calc(30);
        }
    }

    .ctrlangchooser {
        display:flex;
        gap:1em;
    }

    //level1
    li.level1 > a {
        padding:0.3em 0;
        @include breakpoint(small down) {
            padding:0.4em 0;
        }
    }

    //level2
    li.level2 > a {
        padding-left:3em;
        padding-top:0.4em;
        padding-bottom:0.4em;
    }
    li.level2:first-child > a {
        padding-top:0;
    }
    li.level2:last-child > a {
        padding-bottom:0.6em;
    }

    //dropdown icon
    li.hassubmenu > a {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .icon-menuarrowdown {
        height:rem-calc(18);
        transition: transform .2s;
        
        //make it easier to click element
        margin:-5px -10px;
        padding:5px 10px;
    }
    a.expanded .icon-menuarrowdown {
        transform:rotate(90deg);
    }
}

//show/hide transitions
#overlaymenu {
    transition:opacity .4s;
    .ctrmenu {
        transition: transform .4s;
    }
}
#overlaymenu:not(.visible) { 
    pointer-events: none;
    opacity:0;
    .ctrmenu {
        transform: translateY(50px);
    }
}

//arrow for active menuitem
#overlaymenu .ctrmenu {
    li.active::before {
        content:"";
        position: absolute;
        width:0.85em;
        height:0.85em;
        margin-left:-1.2em;
        margin-top:0.325em;
        @include breakpoint(small down) {
            margin-top:0.425em;
        }

        background-image: url('/assets/app/images/icons/menuarrowright.svg');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

//hover animation for menuitems
#overlaymenu .ctrmenu {
    li {
        transition: transform .2s, opacity .2s;
    }
    li:not(.active):hover {
        transform: translateX(0.5em);
        opacity:0.6;
    }
}

//hover animation for language chooser
#overlaymenu .ctrlangchooser {
    a {
        transition: opacity .2s;
    }
    a:not(.active):hover {
        opacity:0.6;
    }
}

//typo
#overlaymenu {
    li {
        @include typo-menuitems;
    }
    /*
    li.level2 > a {
        @include typo-menuitems-level2;
    }
    */

    .ctrlangchooser {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 28);
        font-weight: 400;
        line-height:math.div(32, 28);
     
        .active {
            @include text-decoration-underline;
        }
    }
}