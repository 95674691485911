//positioning
.bl-aktuelllist {
    @include defaultsectionpadding;

    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            row-gap:0;
            .ctrtitle {
                grid-column: 1 / span 12;
            }
            .ctritems {
                grid-column:1 / span 7;
            }
        }
    }
}

//styling
.bl-aktuelllist {
    .ctrtitle {
        margin-bottom: 2em;
        @include breakpoint(small down) {
            margin-bottom:1em;
        }
    }
    .ctrbuttons {
        margin-top:1.75em;
    }
}

//typo
.bl-aktuelllist {
    .ctrtitle {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 24);
        font-weight: 400;
        line-height: math.div(28, 24);
        color:$black;
    }
    h2 {
        color:$black;
    }
}