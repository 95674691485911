//Standardabstand zwischen zwei Bloecken
.cblock + .cblock {
    //@include rem-calc-sl(margin-top, 70, 0.5);
}

//Logik fuer Spacer
//(Vor und nach Spacer kein Abstand. Nur Abstand des Spacers selber.)
.cblock + .bl-spacer,
.bl-spacer + .cblock {
    margin-top:0;
}

//Spezifische Abstaende
.bl-richtext + .cblock {
    @include rem-calc-sl(margin-top, 160, 0.5);
}

.bl-richtext + .bl-filelist {
    @include rem-calc-sl(margin-top, 40, 0.75);
}
.bl-filelist + .bl-richtext {
    @include rem-calc-sl(margin-top, 160, 0.75);
}

/*
:is(.bl-imageblock, .bl-textwithimage) + :is(.bl-imageblock, .bl-textwithimage) {
    @include rem-calc-sl(margin-top, 70, 0.5);
}

//Abstand nach dem Titel
.bl-title + .cblock {
    @include rem-calc-sl(margin-top, 60, 0.5);
}
*/


