html, body {
  overflow-x: clip;
  //overflow-x: hidden breaks sticky (z.B. Block "Timeline"/.bl-vorgehen)
  //https://www.terluinwebdesign.nl/en/css/position-sticky-not-working-try-overflow-clip-not-overflow-hidden/
}

body {
  margin:0;
  padding:0;

  background-color: $bodybg;
}

main {
  background-color: $white;
  margin-bottom:var(--footerheight);
}



/*
  //Test only
html, body {
  height: 100% !important;
  //overflow-y: scroll !important;
  scroll-snap-type: y mandatory !important;
}

.item {
  scroll-snap-align: start !important;
}
*/

/*
 //In Chrome Mac geht das Footer-aufdecken nicht mehr
 //In Fiewfox Mac allgemein buggy. Snap nur bei Klick auf body.
html, body {
  height: 100% !important;
  //overflow-y: scroll !important;
  scroll-snap-type: y proximity !important;
}

#snap-pageend {
  scroll-snap-align: end !important;
}
*/