//positioning
.bl-textwithbutton {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            .ctrtext, .ctrbuttons {
                grid-column: 1 / span 7;
            }
        }
    }
    @include breakpoint(medium down) {
        .ctrbuttons {
            margin-top:var(--layoutgridgap);
        }
    }
}

//styling
.bl-textwithbutton {
    @include defaultsectionpadding;
}

//typo
.bl-textwithbutton {
    .ctrtext {
        @include typo-richtext;
    }
}

