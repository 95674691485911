$black:#2A2A2A;
$white:#fff;
    $farbe1:#52277b;
    $farbe2:#ef2f69; 
    $lightbg: mix($farbe1, #eee, 10%);

$bodybg: $white;

$textcolor:#2A2A2A;

$formerror: red;

$bgblue:#49889E;
$orange:#DB7A41;
$orangeHover:darken($orange, 10%);
$blau:#49889E;
$blaudunkel:#1A6B80;
$blauMoreHover:#145666;

/*
@mixin borderradius {
    border-radius: rem-calc(10);
}
*/

$buttonbg:$farbe2;
$buttonbg-hover:lighten($buttonbg, 10%);

@mixin verlauf-blau-hell {
    background: linear-gradient(277.56deg, #49889E 0%, #1A6B80 99.68%);
}
@mixin verlauf-blau-dunkel {
    background: linear-gradient(90deg, #1A6B80 0%, #145666 100%);
}

:root {
    --layoutgridgap:2rem;

    @include rem-calc-sl(--lrminmargin, 120, math.div(20, 120));

    --footerheight:447px; //is be polled in footer.js
}

@mixin defaultsectionpadding {
    @include rem-calc-sl(padding-top, 160, 0.5);
    @include rem-calc-sl(padding-bottom, 160, 0.5);
}
