//positioning
.bl-moreservices {
    .ctritems {
        @include breakpoint(medium) {
            @include grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 0;
        }
        @include breakpoint(small down) {
            width:75%;
            margin-left: auto;
            margin-right: auto;
        }
    }    
}

//styles
.bl-moreservices {
    @include defaultsectionpadding;
    @include breakpoint(medium) {
        .item:nth-child(n + 2) {
            border-left:2px solid $bgblue;
        }
    }
    @include breakpoint(small down) {
        .item:nth-child(n + 2) {
            border-top:2px solid $bgblue;
        }
    }
    .item {
        padding:1.35em;
        @include breakpoint(medium only) {
            &:first-child {
                padding-left:0;
            }
            &:last-child {
                padding-right:0;
            }
        }
        @include breakpoint(small down) {
            padding-top:1.5em;
            padding-bottom:1.5em;
        }
        .ctrimage {
            margin-left: auto;
            margin-right: auto;
            max-width: rem-calc(200);
            img {
                @include w100bl;
            }
        }
    }
    .ctrtext {
        margin-top: 1.5em;
    }
}

//Hover effect
.bl-moreservices {
    .ctrimage, .ctrtext {
        transition: transform 0.2s;
    }
    .item:hover {
        .ctrimage {
            transform:scale(1.1);
        }
        .ctrtext {
            transform:translateY(-15%);
        }
    }
}

//typo
.bl-moreservices {
    h4 {
        color:$blaudunkel;
        margin-bottom: 0;
    }
}
