.wconstrain {
    margin-left: auto;
    margin-right: auto;

    max-width: calc(100% - var(--lrminmargin)*2);

    //Aktivieren, um max. Breite zu forcieren
    &.w_m {
        width: rem-calc(1104);
    }

    &.w_overlaymenu {
        width: rem-calc(1104);
        @media (min-width:1600px) {
            width: calc(100vw - 700px);
        }
    }

    &.w_full {
        max-width: none;
    }
}
