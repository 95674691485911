//positioning
.bl-textwithimagesmall {
    @include breakpoint(large) {
        .item {
            @include grid12;
            .ctrimage {
                grid-column: 1 / span 2;
                padding-right:10%;
            }
            .ctrtext {
                grid-column: 3 / span 7;
                @include breakpoint(large only) {
                    grid-column: 3 / span 8;
                }
            }
        }
    }
    @include breakpoint(medium down) {
        .ctrtext {
            margin-top: 1.75em;
        }
    }
    .item + .item {
        @include rem-calc-sl(margin-top, 160, 0.5);
    }
}

//styling
.bl-textwithimagesmall {
    .ctrimage img {
        @include w100bl;
        
        @include breakpoint(medium down) {
            @include rem-calc-sle(max-width, 200, 96)
        }
    }

    .buttons {
        margin-top:1.75em;
    }
}

//typo
.bl-textwithimagesmall {
}