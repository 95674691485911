@mixin typo-richtext {
    a {
        @include text-decoration-underline;
        text-decoration-color: $orange;
        &:hover {
            color:$orange;
        }
    }
    /*
    a[href^="tel"] {
        text-decoration: none;
    }
    */

    @include contenttable;
}

.bl-richtext {
    @include breakpoint(large) {
        .ctrtext {
            @include grid12;
            & > .linner {
                grid-column: 1 / span 7;
                @include breakpoint(large only) {
                    grid-column: 1 / span 8;
                }
            }
        }
    }

    /*
    &.dashlist {
        ul {
            @include reset-list;
        }
        li:before {
            //reset default bullet
            display: none;
        }

        li {
            border-top:2px dashed currentColor;
            padding:0.4em 0 0.2em 0;
        }
        ul {
            border-bottom:2px dashed currentColor;
            font-weight: 400;
        }
    }
    */
}

//typo
.bl-richtext {
    @include typo-richtext();
}
