#menubuttonmobile {
    display: none;

    position: fixed;
    z-index: 200;
    left:50%;
    transform: translateX(-50%);
    bottom:rem-calc(30);

    background-color: #145666;
    box-shadow: 0px 1px 3px 0px #0000004D,
        0px 4px 8px 3px #00000026;
    border-radius: 999px;

    svg {
        display: block;
        width:rem-calc(60);
        height:rem-calc(60);
    }
    .icon-menubuttonmobileclose {
        display: none;
    }
    .overlaymenuvisible & {
        .icon-menubuttonmobile {
            display: none;
        }
        .icon-menubuttonmobileclose {
            display:block;
        }
    }

    cursor: pointer;

    @include breakpoint(small down) {
        display:block;
    }
}
