//positioning
.bl-hero {
    @include breakpoint(large) {
        .ctrtext {
            @include grid12;
            .linner {
                grid-column: 1 / span 10;
            }
        }
    }
}

//styling
.bl-hero {
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat;
    @media(min-width:2000px) {
        background-size:2000px auto;
    }
    .ishome1 & {
        background-image: url(/assets/app/images/bg/hero.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/heromobile.png);
        }
    }
    .ishome0 & {
        background-image: url(/assets/app/images/bg/herofs.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/herofsmobile.png);
        }
    }

    position: relative;
    .wconstrain {
        position: relative;
        height:100%;
    }

    .ctrscrolldownarrow {
        @include rem-calc-sle(margin-top, 80, 30);
    }
    .icon-heroscrolldownarrow {
        cursor: pointer;
        color:$orange;
        @include rem-calc-sl(height, 58, 0.5);
    }

    .breadcrumb {
        margin-bottom:2em;
        @include breakpoint(small down) {
            margin-bottom:1em;
        }
    }
}

//hero on start page: special styling
.ishome1 .bl-hero {
    .breadcrumb {
        display:none;
    }
    .ctrtext {
        @include rem-calc-sle(padding-top, 500, 222);
    }
}

//hero on Folgeseite: special styling
.ishome0 .bl-hero {
    @include rem-calc-sle(padding-top, 300, 160);
    @include rem-calc-sle(padding-bottom, 160, 120);

    .ctrscrolldownarrow {
        display:none;
    }
}

//typo
.bl-hero {
    @include typo-hero;
    color:$blau;
    h1 {
        font-size:inherit;
        margin-bottom: 0;
    }
    .breadcrumb {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 24);
        font-weight: 400;
        line-height: math.div(28, 24);
        color:$blau;
    }
}