//positioning
.bl-quote {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            .ctrmain {
                grid-column:2 / span 10;
            }
        }
    }
}

//styles
.bl-quote {
    @include defaultsectionpadding;

    .author {
        margin-top:2em;
    }
}

//typo
.bl-quote {
    .quote {
        @include typo-quote;
    }
    .author {
        @include typo-quoteauthor;
    }
}