section {
    @include rem-calc-sl(--baseunit, math.div(160,3), 0.5);
    @for $i from -5 through 10 {
        &.pt-#{$i} {
            padding-top:calc(#{$i} * var(--baseunit));
        }
        &.pb-#{$i} {
            padding-bottom:calc(#{$i} * var(--baseunit));
        }

        &.mt-#{$i} {
            margin-top:calc(#{$i} * var(--baseunit));
        }
        &.mb-#{$i} {
            margin-bottom:calc(#{$i} * var(--baseunit));
        }
    }
    //Ausnahme
    @include breakpoint(small down) {
        &.pb-3 {
            padding-bottom:rem-calc(120);
        }
    }
}

//new system
@mixin sectionbgpos {
    background-repeat: no-repeat;
    @media (max-width:1599px) {
        background-size: 100% auto;
        background-position: center top;
    }
    @media (min-width:1600px) {
        background-size: 1600px auto;
        &.sectionbg-1 {
            background-position: left top;
        }
        &.sectionbg-2 {
            background-position: right top;
        }
    }
}
.sectionbg-img-default {
    @include sectionbgpos;

    &.sectionbg-auto, &.sectionbg-1 {
        background-image: url(/assets/app/images/bg/section01.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/section01mobile.png);
        }
    }
    &.sectionbg-2 {
        background-image: url(/assets/app/images/bg/section02.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/section02mobile.png);
        }
    }
}
.sectionbg-img-quote {
    background-color: $bgblue;

    @include sectionbgpos;

    &.sectionbg-auto, &.sectionbg-1 {
        background-image: url(/assets/app/images/bg/quote01.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/quote01mobile.png);
        }
    }
    &.sectionbg-2 {
        background-image: url(/assets/app/images/bg/quote02.png);
        @include breakpoint(small down) {
            background-image: url(/assets/app/images/bg/quote02mobile.png);
        }
    }
}
.sectionbg-img-aktuell {
    background-repeat: no-repeat;
    @media (max-width:1599px) {
        background-size: 100% auto;
        background-position: center top;
    }
    @media (min-width:1600px) {
        background-size: 100% auto, 1600px auto;
        &.sectionbg-1 {
            background-position: center top, left top;
        }
        &.sectionbg-2 {
            background-position: center top, right top;
        }
    }

    $bgGradientColor: #E0DBD2;
    &.sectionbg-auto, &.sectionbg-1 {
        background-image: linear-gradient(to bottom, rgba($bgGradientColor, 0.5), rgba($bgGradientColor, 0)), url(/assets/app/images/bg/aktuell01.png);
        @include breakpoint(small down) {
            background-image: linear-gradient(to bottom, rgba($bgGradientColor, 0.5), rgba($bgGradientColor, 0)), url(/assets/app/images/bg/aktuell01mobile.png);
        }
    }
    &.sectionbg-2 {
        background-image: linear-gradient(to bottom, rgba($bgGradientColor, 0.5), rgba($bgGradientColor, 0)), url(/assets/app/images/bg/aktuell02.png);
        @include breakpoint(small down) {
            background-image: linear-gradient(to bottom, rgba($bgGradientColor, 0.5), rgba($bgGradientColor, 0)), url(/assets/app/images/bg/aktuell02mobile.png);
        }
    }
}