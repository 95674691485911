/*
100 	extralight/ultralight
200 	light/thin
300 	book/demi/light
400 	regular/normal
500 	medium
600 	semibold/demibold
700 	bold
800 	black/extrabold/heavy
900 	extrablack/fat/poster/ultrablack/heavy
https://gist.github.com/lukaszgrolik/5849599#file-font-weights-md
*/

@font-face {
    font-display: swap;
    font-family: 'ElenaGS';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/ElenaGSWebRegular.woff2') format('woff2');
    //https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/src#font_formats
}

@font-face {
    font-display: swap;
    font-family: 'ElenaGS';
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/ElenaGSWebRegularItalic.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'ElenaGS';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/ElenaGSWebBold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'ElenaGS';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/ElenaGSWebBoldItalic.woff2') format('woff2');
}


@font-face {
    font-display: swap;
    font-family: 'BreuerTextGS';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/BreuerTextGS-Regular.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'BreuerTextGS';
    font-weight: 400;
    font-style: italic;
    src: url('../fonts/BreuerTextGS-RegularItalic.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'BreuerTextGS';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/BreuerTextGS-Bold.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'BreuerTextGS';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/BreuerTextGS-BoldItalic.woff2') format('woff2');
}

