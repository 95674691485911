//positioning
.bl-textwithimage {
    @include breakpoint(large) {
        .posgrid {
            @include grid12;
            row-gap:0;
            .ctrtitle {
                grid-row:1;
                grid-column: 6 / span 7;
            }
            .ctrimage {
                grid-row:2;
                grid-column: 1 / span 5;
            }
            .ctrtext {
                grid-row:2;
                grid-column: 6 / span 7;
            }
        }

        .item:nth-child(2n) {
            .posgrid {
                .ctrtitle {
                    grid-column: 1 / span 7;
                }
                .ctrtext {
                    grid-column: 1 / span 7;
                }
                .ctrimage {
                    grid-column: 8 / span 5;
                }
            }
        }
    }
    @include breakpoint(medium down) {
        .ctrtitle {
            margin-top:1.5em;
        }
    }
}

//styling
.bl-textwithimage {
    .ctrimage img {
        @include w100bl;
        
        @include breakpoint(medium down) {
            max-width:35vw;
            min-width:rem-calc(160);
        }
    }

    .item {
        @include defaultsectionpadding;
    }

    .buttons {
        margin-top:1.75em;
    }
}

//typo
.bl-textwithimage {
}