//positioning
.bl-personlist {
    @include breakpoint(medium) {
        &.start-r {
            --startcol:3;
            --altcol:1;
        }
        &.start-l {
            --startcol:1;
            --altcol:3;
        }
        .ctrtitle {
            @include grid12;
            h3 {
                grid-column: var(--startcol) / span 10;
            }
        }
        .item {
            @include grid12;
            .itempos {
                grid-column: var(--startcol) / span 10;
                @include breakpoint(medium only) {
                    grid-column: 1 / span 12;
                }
            }
            &:nth-child(2n) {
                .itempos {
                    grid-column: var(--altcol) / span 10;
                    @include breakpoint(medium only) {
                        grid-column: 1 / span 12;
                    }
                }
            }
        }
        .itembox {
            @include grid;
            row-gap: 0;
            grid-template-columns: repeat(10, 1fr);
            .ctrimage {
                grid-column: 3 / span 8;
            }
            .ctrinfo {
                grid-column: 1 / span 5;
                margin-top:rem-calc(-220);
                @include breakpoint(medium only) {
                    margin-top:rem-calc(-9 * 16);
                }
            }
            .ctrlongtext {
                margin-top:2.5em;
                grid-column: 1 / span 7;
                @include breakpoint(medium only) {
                    grid-column: 1 / span 10;
                }
            }
        }
    }
    @include breakpoint(small down) {
        .ctrlongtext {
            margin-top:2.5em;
        }
    }
    .item + .item {
        @include rem-calc-sl(margin-top, 240, 0.5);
    }
}

//styling
.bl-personlist {
    .ctrimage {
        img {
            @include w100bl;
        }
    }
    .ctrinfo {
        .part1 {
            padding: 0.6em 0.6em 1em 0.6em;
            background-color: $white;
        }
        .part2 {
            padding: 0.6em 0.6em 1.6em 0.6em;
            @include verlauf-blau-hell;
        }
        .info2 {
            margin-bottom: 1em;
        }
    }
    .ctrmorelink {
        padding:0.5em 1em;
        span {
            display:flex;
            justify-content: flex-end;
            align-items: center;
            gap:0.5em;
        }
        @include verlauf-blau-dunkel;
    }
    .socialicons {
        margin-top:1em;
        display:flex;
        gap:0.5em;
        .icon {
            display: block;
            height:rem-calc(30);
        }
    }
}

//hover effect
.bl-personlist {
    .ctrinfo .part2 a {
        transition: opacity 0.2s;
        &:hover {
            opacity: 0.6;
        }
    }
}

//hover effect more link
.bl-personlist {
    .ctrmorelink {
        position: relative;
        span {
            position: relative; //bring to front
        }
        .bgoverlay {
            @include absposfull;
            opacity: 0;
            transition: opacity .4s;
            background-color: $blauMoreHover;
        }
        .icon-moreinfoarrow {
            transition:transform .4s;
        }
    }
    .ctrmorelink:hover {
        .bgoverlay {
            opacity: 1;
        }
        .icon-moreinfoarrow {
            transform: translateX(5px);
        }
    }
}

//typo
.bl-personlist {
    .ctrtitle {
        h3 {
            margin-bottom: 1em;
        }
    }
    .ctrinfo {
        font-family:'BreuerTextGS', sans-serif;
        @include font-size-pt(19, 25);
        font-weight: 400;
        line-height: math.div(32, 25);
        .part2 {
            color:$white;
        }
    }
    .ctrmorelink {
        @include typo-button;
        color:$white;
    }    
}

